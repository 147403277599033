import scrollLock from 'scroll-lock';

export default class FilterMenu {
	constructor() {
		this.filterMenu = document.querySelector('[data-filter-form]');

		this.openBtns = document.querySelectorAll('[data-filter-open]');
		this.openBtns.forEach((btn) => {
			btn.addEventListener('click', this.openFilterMenu.bind(this));
		});

		this.closeBtns = document.querySelectorAll('[data-filter-close]');
		this.closeBtns.forEach((btn) => {
			btn.addEventListener('click', this.closeFilterMenu.bind(this));
		});
	}

	openFilterMenu(e) {
		e.preventDefault();
		this.filterMenu.classList.add('is-active');
		scrollLock.disablePageScroll(this.filterMenu);
	}

	closeFilterMenu(e) {
		e.preventDefault();
		this.filterMenu.classList.remove('is-active');
		scrollLock.enablePageScroll();
	}
}
