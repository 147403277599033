import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


export default class LinkCarousel {
    constructor($el) {
        this.swiper = new Swiper($el, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 39,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 3,
                },
            },
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}