import mixitup from 'mixitup';
import mixitupMultifilter from '../vendor/mixitup-multifilter';
import mixitupPagination from '../vendor/mixitup-pagination';

export default class MixFilter {
	constructor($filter) {

		this.filterContainer = $filter;
		this.cardsContainer = document.querySelector('[data-card-container]');
		this.checkedInputs = null;
		this.initialState = true;
		this.originalCollection = this.cardsContainer.querySelectorAll('[data-filter-card]');

		this.resetBtns = document.querySelectorAll('[data-filter-reset]');

		this.resetBtns.forEach((btn) => {
			btn.addEventListener('click', this.resetFilters.bind(this));
		});

		this.filterContainer.addEventListener('change', this.filter.bind(this));

		this.filterContainer.addEventListener('submit', (e) => {
			e.preventDefault();
			this.filter();
		});

		mixitup.use(mixitupMultifilter);
        mixitup.use(mixitupPagination);

		this.mixer = mixitup(this.cardsContainer, {
			selectors: {
				target: '[data-filter-card]',
			},
			animation: {
				duration: 400,
				queueLimit: 5,
				effects: 'fade translateY(100px)',
			},
			multifilter: {
				enable: true,
			},
            pagination: {
                limit: 8,
				hidePageListIfSinglePage: true,
            },
			templates: {
				pagerPrev: '<button type="button" class="${classNames} page-navbtn" data-page="prev"><svg class="icon icon-ac_button_back"><use xlink:href="#icon-ac_button_back"></use></svg></button>',
				pagerNext: '<button type="button" class="${classNames} page-navbtn" data-page="next"><svg class="icon icon-ac_button_next"><use xlink:href="#icon-ac_button_next"></use></svg></button>',
			},
			load: {
				filter: 'none',
			},
			callbacks: {
				onMixEnd: (state, futureState) => {
					// if (state.totalPages > 1) {
					// 	document.body.classList.add('show-pagination');
					// } else {
					// 	document.body.classList.remove('show-pagination');
					// }
				},
				onPaginateStart: () => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		});

		this.filter(true);
	}

	resetFilters() {
		this.checkedInputs.forEach((checkedInput) => {
			checkedInput.checked = false;
		});

		this.searchInput.value = '';

		this.filter(true)
	}

	filter(interaction = true) {
		if (interaction) {
			this.initialState = false;
		}

		this.checkedInputs = this.filterContainer.querySelectorAll(':checked');

		let selector = '.c-card';

		// custom selector based on checked inputs
		if (this.checkedInputs.length) {
			selector = '';

			this.checkedInputs.forEach(input => {
				selector += `.${input.value}`;
			});
		}

		// collection cards with the default selector or based on custom selector
		let collectionCards = this.cardsContainer.querySelectorAll(selector);

		this.mixer.filter(collectionCards);

		let itemCount = collectionCards.length;
		this.failMessage = document.querySelector('[data-fail-message]');
        if (this.failMessage) {
            if (itemCount <= 0) {
                this.failMessage.style.display = 'block';
            } else {
                this.failMessage.style.display = 'none';
            }
        }
	}
}
