import Swiper from 'swiper'
import 'swiper/css';

export default class Carousel {
    constructor($el) {
        this.nextbtn = $el.querySelector('[data-next]');

        this.swiper = new Swiper($el, {
            loop: true,
        });

        this.nextbtn.addEventListener('click', () =>{
            this.swiper.slideNext();
        })
    }
}